<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 版心 -->
    <div id="gift-code-convert-layout">
      <div class="i-page-header">
        <img src="@/assets/common/goBack.png" style="width: 24px;float: left;" @click="$router.push('/index')" />
        <div style="float: right;background-color: rgba(220, 215, 227, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-left: 4px;margin-right: 8px;"> {{goldNum}}</span>
          <img src="@/assets/common/plusSign.png" @click="goApp()" style="width: 10px;vertical-align: middle;" />
        </div>
      </div>
      <div style="margin-top: 10%;">
        <img src="@/assets/icon/luckyDynamicIslandConvertIcon.png" style="width: 66px;vertical-align: middle;margin-bottom: 6%;" />
        <div style="margin: auto 4%;">
          <el-form style="text-align: left;" label-position="top">
            <el-form-item :label="$t('giftCode.i_enterGiftReceiveCoins')">
              <el-input type="text" v-model="giftCode" :placeholder="$t('giftCode.i_enterGiftCode')" style="width: 55%;" />
              <el-button v-if="giftCode != null" @click="toCheckGiftCode()" round style="background-color: rgba(141, 105, 253, 1);color: white;margin-left: 4%;">
                {{ $t('giftCode.i_confirm') }}
              </el-button>
              <el-button v-else round style="background-color: rgba(184, 175, 212, 1);color: white;margin-left: 4%;">
                {{ $t('giftCode.i_confirm') }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="margin-top: 54%;">
        <div style="overflow: hidden;margin-top: 10%;">
          <div style="border: 1px solid rgba(184, 175, 212, 1);width: 28%;float: left;margin-left: 10%;margin-top: 12px;"></div>
          <img src="@/assets/images/renmen.png" style="width: 28px;vertical-align: middle;" />
          <div style="border: 1px solid rgba(184, 175, 212, 1);width: 28%;float: right;margin-right: 10%;margin-top: 12px;"></div>
          <div style="font-size: 14px;color: rgba(184, 175, 212, 1);margin-top: 6px;">{{ $t('giftCode.i_joinCommunityGetGiftCode') }}</div>
        </div>
        <el-button type="primary" @click="crowdDialog=true" round style="width: 60%;margin: 5%;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">{{ $t('giftCode.i_joinNow') }}</el-button>
      </div>
    </div>

    <el-dialog :title="$t('giftCode.i_successfulExchange')" :visible.sync="rewardDialog" width="68%" center>
      <div style="text-align: center;">
        <img src="@/assets/common/giftCodeGoldIcon.png" style="width: 50px;vertical-align: middle;" />
        <div style="margin-top: 4px;">+{{ rewardNum }} {{ $t('giftCode.i_coins') }}</div>
        <el-button type="primary" @click="rewardDialog=false" round style="width: 60%;margin-top: 30px;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">{{ $t('giftCode.i_ok') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('giftCode.i_selectCommunity')" :visible.sync="crowdDialog" width="80%" center>
      <div style="text-align: center;">
        <div style="text-align: center;">
          <img src="@/assets/common/telegram.png" style="width: 36px;vertical-align: middle;display: inline-block;" />
          <span style="font-size: 18px;margin: 6% 4%;display: inline-block;width: 80px;">telegram</span>
          <el-button type="primary" @click="goWeb('https://t.me/+xvavC-feFgtmMjRl')" size="small" round style="display: inline-block;background-color: rgba(141, 105, 253, 1);">{{ $t('giftCode.i_joinNow') }}</el-button>
        </div>
        <div style="text-align: center;">
          <img src="@/assets/common/whatsapp.png" style="width: 36px;vertical-align: middle;display: inline-block;" />
          <span style="font-size: 18px;margin: 6% 4%;display: inline-block;width: 80px;">whatsapp</span>
          <el-button type="primary" @click="goWeb('https://chat.whatsapp.com/HepDFKirSra5zpdND3wulv')" size="small" round style="display: inline-block;background-color: rgba(141, 105, 253, 1);">{{ $t('giftCode.i_joinNow') }}</el-button>
        </div>
        <div style="text-align: center;">
          <img src="@/assets/common/meta.png" style="width: 36px;vertical-align: middle;display: inline-block;" />
          <span style="font-size: 18px;margin: 6% 4%;display: inline-block;width: 80px;">meta</span>
          <el-button type="primary" @click="goWeb('https://www.facebook.com/groups/luckysoft01')" size="small" round style="display: inline-block;background-color: rgba(141, 105, 253, 1);">{{ $t('giftCode.i_joinNow') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        goldNum: 0,
        crowdDialog: false,
        giftCode: null,
        rewardDialog: false,
        rewardNum: 0
      };
    },
    methods: {
      goApp() {
        location.href = "luckydynamicisland://";
      },
      goWeb(url) {
        location.href = "luckydynamicisland://" + "external?url=" + url;
      },
      toCheckGiftCode() {
        if (this.giftCode != null && this.giftCode != "") {
          this.isLoading = true;
          this.$axios({
            url: "/island/giftWeb/web/checkGiftCode",
            method: "get",
            params: {
              giftCode: this.giftCode
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.rewardNum = result.data.data.rewardNum;
              this.rewardDialog = true;
              this.giftCode = null;
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        }
      }
    },
    created() {}
  };
</script>

<style>
  #gift-code-convert-layout {
    width: 100%;
    height: 100%;
  }
  #gift-code-convert-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
  #gift-code-convert-layout .el-input__inner {
    background-color: rgba(211, 246, 244, 1);
  }
</style>
