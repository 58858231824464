<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-info-layout">
      <div class="ci-page-header">
        <img src="@/assets/common/goBack.png" style="width: 24px;float: left;" @click="$router.push('/index')" />
        <div style="float: right;background-color: rgba(220, 215, 227, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img :src="giftInfo.giftCardIcon" style="width: 18px;vertical-align: middle;margin-left: 2px;" />
          <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-left: 4px;margin-right: 4px;">{{giftInfo.cash}}<span style="font-size: 13px;margin-left: 2px;">{{giftInfo.unit}}</span></span>
        </div>
      </div>

      <div style="margin-left: 8%;padding-right: 8%;">
        <div v-html="giftCard.remarks" style="margin-top: 4%;margin-bottom: 2%;white-space: pre-wrap;word-break: break-word;line-height: 26px;text-align: left;color: rgba(86, 137, 138, 1);"></div>
      </div>

      <div style="margin-left: 8%;margin-right: 8%;">
        <el-form ref="bindGiftCard" :model="bindGiftCard" :rules="formRules" style="text-align: center;">
          <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
            <el-input v-if="form.keyName == 'account'" type="text" v-model="bindGiftCard.account" :placeholder="form.placeholder" />
            <el-input v-if="form.keyName == 'accountName'" type="text" v-model="bindGiftCard.accountName" :placeholder="form.placeholder" />
            <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="bindGiftCard.extraOne" :placeholder="form.placeholder" />
            <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="bindGiftCard.extraTwo" :placeholder="form.placeholder" />
          </el-form-item>

          <el-form-item prop="giftCardItemId">
            <div style="overflow: hidden;">
              <span style="float: left;line-height: 28px;margin-left: 2px;">{{ $t('test.i_selectExchangeAmount') }}</span>
            </div>
            <el-radio-group v-model="bindGiftCard.giftCardItemId" style="margin-top: 5%;width: 100%;">
              <el-row :gutter="12">
                <div v-for="item in giftCardItemList" :key="item.id" style="">
                  <el-col :span="8">
                    <el-radio-button :label="item.id" style="margin-bottom: 10px;">
                      <div style="margin-top: 2px;">
                        <span style="">{{item.price}} {{giftInfo.unit}}</span>
                      </div>
                    </el-radio-button>
                  </el-col>
                </div>
              </el-row>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <div style="margin: 6%;">
        <div style="margin-top: 10%;margin-bottom: 5%;">
          <div @click="toConvert('bindGiftCard')" style="position: relative;">
            <img src="@/assets/images/convert/convertBtn.png" style="width: 210px;height: 44px;;margin-bottom: 4%;" />
            <span style="position: absolute;box-sizing: border-box;left: 0;right: 0;color: white;">
              <div style="height: 8px;"></div>
              <img :src="giftInfo.giftCardIcon" style="width: 40px;vertical-align: middle;" />
              <span style="vertical-align: middle;margin-left: 6px;">{{ $t('convert.i_redeemNow') }}</span>
            </span>
          </div>
        </div>

        <el-dialog :title="$t('convert.i_checkConvertInfo')" :visible.sync="checkConvertDialog" width="85%" center>
          <div v-for="item in giftCardItemList" :key="item.itemId">
            <div v-if="item.id == bindGiftCard.giftCardItemId">
              <img :src="giftInfo.giftCardIcon" style="width: 36px;vertical-align: middle;margin-right: 4px;" />
              <span style="font-size: 22px;vertical-align: middle;">{{item.price}} {{giftInfo.unit}}</span>
            </div>
          </div>

          <div v-for="(form, index) in giftCard.forms" :key="index" style="margin-top: 4%;">
            <label>{{ form.label }}：</label>
            <div style="color: rgba(0, 183, 0, 1);">
              <p v-if="form.keyName == 'account'">{{ bindGiftCard.account }}</p>
              <p v-if="form.keyName == 'accountName'">{{ bindGiftCard.accountName }}</p>
              <p v-if="form.keyName == 'extraOne'">{{ bindGiftCard.extraOne }}</p>
              <p v-if="form.keyName == 'extraTwo'">{{ bindGiftCard.extraTwo }}</p>
            </div>
          </div>

          <div style="margin-top: 12%;text-align: center;">
            <el-button type="primary" @click="onConvert()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
              {{ $t('convert.i_redeemNow') }}
            </el-button>
            <div style="margin-top: 4%;">
              <span style="color: rgba(144, 144, 144, 1);" @click="checkConvertDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
            </div>
          </div>
        </el-dialog>

        <el-dialog :title="$t('convert.i_convert') + ' - ' + $t('convert.i_tips')" :visible.sync="convertDialog" width="85%" center>
          <div style="margin-bottom: 6%;text-align: center;">
            <img src="@/assets/images/convert002.png" style="margin-bottom: 6%;width: 70px;" />
            <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{ $t('convert.i_convertMsg') }}</div>
          </div>
          <div style="text-align: center;">
            <el-button type="primary" @click="$router.push('/convertLog')" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["id"],
    data() {
      const checkWhetherSpace = (rule, value, callback) => {
        this.giftCard.forms.forEach((form) => {
          if (form.keyName == rule.field) {
            if (form.regexps != null && !new RegExp(form.regexps).test(value)) {
              callback(new Error(form.regexpsTips));
            } else {
              callback();
            }
          }
        });
      };
      return {
        isLoading: false,
        convertMsg: "",
        convertStatus: 3,
        convertDialog: false,
        convertMsgDialog: false,
        checkConvertDialog: false,
        giftInfo: {},
        giftCard: {},
        giftCardItemList: {},
        bindGiftCard: {
          gid: null,
          account: null,
          accountName: null,
          extraOne: null,
          extraTwo: null,
          giftCardItemId: null
        },
        formRules: {
          account: [
            {
              required: true,
              message: this.$t("convert.i_accountN"),
              trigger: ["blur", "change"]
            },
            {
              validator: checkWhetherSpace,
              trigger: ["blur", "change"]
            }
          ],
          accountName: [
            {
              required: true,
              message: this.$t("convert.i_nameN"),
              trigger: ["blur", "change"]
            },
            {
              validator: checkWhetherSpace,
              trigger: ["blur", "change"]
            }
          ],
          extraOne: [
            {
              required: true,
              validator: checkWhetherSpace,
              trigger: ["blur", "change"]
            }
          ],
          extraTwo: [
            {
              required: true,
              validator: checkWhetherSpace,
              trigger: ["blur", "change"]
            }
          ],
          giftCardItemId: [
            {
              required: true,
              message: this.$t("test.i_pleaseSelectAnExchangeItem"),
              trigger: ["change"]
            }
          ]
        }
      };
    },
    methods: {
      getGiftInfo() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/giftCardInfo",
          method: "get",
          params: {
            gid: this.id
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            console.log(result.data.data);
            this.giftInfo = result.data.data.giftInfo;
            this.giftCard = result.data.data.giftCardInfo;
            this.giftCardItemList = result.data.data.giftCardItemList;
            this.bindGiftCard.gid = this.giftInfo.giftCardId;
          } else if (result.data.code == 0) {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      convertOk() {
        if (this.convertStatus == 1) {
          this.$router.go(-1);
        } else if (this.convertStatus == 3) {
          this.convertMsgDialog = false;
        }
      },
      onConvert() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/exchange",
          method: "post",
          params: this.bindGiftCard
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.convertDialog = true;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      toConvert(formName) {
        this.$refs[formName].validateField(["account"], (validErr) => {
          if (!validErr) {
            if (this.giftCard.forms.length >= 2) {
              this.$refs[formName].validateField(["accountName"], (validErr) => {
                if (!validErr) {
                  if (this.giftCard.forms.length >= 3) {
                    this.$refs[formName].validateField(
                      ["extraOne"],
                      (validErr) => {
                        if (!validErr) {
                          if (this.giftCard.forms.length >= 3) {
                            this.$refs[formName].validateField(
                              ["extraTwo"],
                              (validErr) => {
                                if (!validErr) {
                                  this.$refs[formName].validateField(
                                    ["giftCardItemId"],
                                    (validErr) => {
                                      if (!validErr) {
                                        this.checkConvertDialog = true;
                                      }
                                    }
                                  );
                                }
                              }
                            );
                          } else {
                            if (!validErr) {
                              this.$refs[formName].validateField(
                                ["giftCardItemId"],
                                (validErr) => {
                                  if (!validErr) {
                                    this.checkConvertDialog = true;
                                  }
                                }
                              );
                            }
                          }
                        }
                      }
                    );
                  } else {
                    if (!validErr) {
                      this.$refs[formName].validateField(
                        ["giftCardItemId"],
                        (validErr) => {
                          if (!validErr) {
                            this.checkConvertDialog = true;
                          }
                        }
                      );
                    }
                  }
                }
              });
            } else {
              if (!validErr) {
                this.$refs[formName].validateField(
                  ["giftCardItemId"],
                  (validErr) => {
                    if (!validErr) {
                      this.checkConvertDialog = true;
                    }
                  }
                );
              }
            }
          }
        });
      }
    },
    created() {
      this.getGiftInfo();
    }
  };
</script>

<style>
  #convert-info-layout {
    width: 100%;
    height: 100%;
  }

  #convert-info-layout .ci-page-header {
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    overflow: hidden;
  }
</style>
