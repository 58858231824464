<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="index-layout">
      <div class="i-page-header">
        <img src="@/assets/common/goBack.png" style="width: 24px;float: left;" @click="goApp()" />
        <div style="float: right;">
          <img src="@/assets/common/logIcon.png" style="width: 20px;margin-right: 2px;vertical-align: middle;" />
          <router-link to="/convertLog" style="text-decoration: none;color: black;vertical-align: middle;">{{$t('convert.i_convertLog')}}</router-link>
        </div>
      </div>
      <div>
        <div style="margin: 3% auto;font-size: 20px;">{{ $t('test.i_pleaseSelectAGiftCard') }}</div>
        <div style="margin: 2% 3%;">
          <el-row :gutter="12">
            <el-col :span="8" v-for="(gift, index) in giftList" :key="gift.id">
              <el-card shadow="hover" :style="{'background-color':(index==0?'rgba(216, 216, 233, 1)':'rgba(240, 240, 247, 1)')}">
                <div v-if="index == 0" style="position: absolute;">
                  <img src="@/assets/images/index/now.png" style="width: 40px;position: absolute;top: -9px;left: -9px;" />
                </div>
                <img :src="gift.giftCardIcon" style="width: 60%;margin-top: 10px;" />
                <div :class="{ 'apply-shake': gift.giftCardId==shakeGiftCardId }" style="margin-top: 10px;font-weight: bold;">{{gift.cash}}</div>
                <div style="font-size: 12px;margin-bottom: 10px;">{{gift.unit}}</div>
                <div @click="goConvert(gift.giftCardId, gift.cash)" style="background: linear-gradient(136.41deg, rgba(149, 146, 184, 1) 0%, rgba(145, 141, 184, 1) 100%);border-radius: 30px;padding: 2px 2px 2px 0px;">
                  <div style="">
                    <img src="@/assets/images/index/convertIcon.png" style="width: 12px;vertical-align: middle;" />
                    <span style="font-size: 12px;color: rgba(255, 255, 255, 0.9);margin-left: 5px;vertical-align: middle;">{{$t('index.i_withdraw')}}</span>
                  </div>
                </div>
                <div style="height: 8px;"></div>
                <div @click="goGiftTurntable(gift.giftCardId)" style="background: linear-gradient(90deg, rgba(221, 28, 137, 1) 0%, rgba(223, 54, 173, 1) 100%);border-radius: 30px;padding: 2px 2px 2px 0px;">
                  <div style="">
                    <img src="@/assets/images/index/drawIcon.png" style="width: 12px;vertical-align: middle;" />
                    <span style="font-size: 12px;color: rgba(255, 255, 255, 0.9);margin-left: 5px;vertical-align: middle;">{{$t('index.i_cashDraw')}}</span>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div>
          <div style="overflow: hidden;margin: 3%;border-radius: 10px;padding: 2%;background: linear-gradient(90deg, rgba(136, 183, 241, 1) 0%, rgba(136, 183, 241, 1) 1.9%, rgba(113, 238, 169, 1) 100%);">
            <div style="display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/giftCodeGoldConvertIcon.png" style="width: 46px;vertical-align: middle;" />
            </div>
            <div style="display: inline-block;vertical-align: middle;margin-left: 3%;margin-right: 10%;">
              <div style="font-size: 15px;font-weight: 900;-webkit-text-stroke: 1px rgba(76, 105, 153, 1);color: #fff;vertical-align: middle;text-align: left;">
                {{ $t('test.i_giftCodeEvent') }}<br />{{ $t('test.i_getMoreCoins') }}
              </div>
            </div>
            <el-button type="primary" round @click="$router.push('/giftCodeConvert')" style="background-color: black;width: 34%;vertical-align: middle;">立即前往</el-button>
          </div>
        </div>
        <div style="margin-top: 5%;margin-bottom: 4%;font-weight: bold;font-size: 20px;">{{ $t('test.i_inviteFriends') }}</div>
        <div style="position: relative;">
          <img src="@/assets/images/index/inviteBg.png" style="width: 94%;margin-bottom: 4%;" />
          <span style="position: absolute;box-sizing: border-box;left: 0;right: 0;color: black;">
            <div style="height: 50px;"></div>
            <span style="font-size: 18px;font-weight: 900;-webkit-text-stroke: 1px rgba(76, 105, 153, 1);color: #fff;">{{ $t('test.i_inviteFriendsToGetMoreCash') }}</span>
            <br />
            <el-button type="primary" round @click="$router.push('/inviteFriend')" style="background-color: black;margin-top: 20px;margin-bottom: 20px;width: 34%;">{{ $t('test.i_inviteNow') }}</el-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        userGiftCardId: null,
        giftList: [],
        shakeGiftCardId: null
      };
    },
    methods: {
      goApp() {
        location.href = "luckydynamicisland://";
      },
      goGiftTurntable(gid) {
        location.href = "luckydynamicisland://draw2?gid=" + gid;
      },
      getGiftList() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/index",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            console.log(result.data.data);
            this.userGiftCardId = result.data.data.userGiftCardId;
            this.giftList = result.data.data.giftWebList;
          } else if (result.data.code == 0) {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goConvert(giftCardId, cash) {
        if (cash > 0) {
          this.$router.push("/convertInfo/" + giftCardId);
        } else {
          this.shakeGiftCardId = giftCardId;
          setTimeout(() => {
            this.shakeGiftCardId = null;
          }, 820); // 动画持续时间为0.82s
        }
      }
    },
    created() {
      if (this.$route.query.token != null) {
        localStorage.setItem("token", this.$route.query.token);
      }
      this.getGiftList();
    }
  };
</script>

<style>
  #index-layout {
    width: 100%;
    height: 100%;
  }
  #index-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
  #index-layout .el-card {
    background-color: rgba(240, 240, 247, 1);
    border-radius: 5px;
    width: 110px;
    margin: 4% auto;
  }
  #index-layout .el-card__body {
    padding: 8%;
  }

  #index-layout .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  #index-layout .apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
</style>
