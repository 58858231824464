import Vue from "vue";
import VueRouter from "vue-router";
import ConvertLog from "@/views/ConvertLog.vue";
import Index from "@/views/Index.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import InviteLog from "@/views/InviteLog.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import Invite from "@/views/Invite.vue"
import ErrorPage from "@/views/ErrorPage.vue";
import GetGiftCode from "@/views/GetGiftCode.vue";
import GiftCodeConvert from "@/views/GiftCodeConvert.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index"
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/inviteLog",
    name: "inviteLog",
    component: InviteLog
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/invite",
    name: "invite",
    component: Invite
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  },
  {
    path: "/get-gift-code",
    name: "getGiftCode",
    component: GetGiftCode
  },
  {
    path: "/giftCodeConvert",
    name: "giftCodeConvert",
    component: GiftCodeConvert
  }
];

const router = new VueRouter({
  routes
});

export default router;
