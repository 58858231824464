<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="invite-friend-layout">
      <div style="background-color: white;">
        <div class="i-page-header">
          <img src="@/assets/common/goBack.png" style="width: 24px;float: left;" @click="$router.push('/index')" />
          <div style="float: right;background-color: rgba(220, 215, 227, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
            <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
            <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-left: 4px;margin-right: 8px;"> {{goldNum}}</span>
            <img src="@/assets/common/plusSign.png" @click="goApp()" style="width: 10px;vertical-align: middle;" />
          </div>
        </div>
        <div style="margin-top: 4%;">
          <div style="position: relative;">
            <img src="@/assets/images/invite/inviteTopBg.png" style="width: 100%;margin-bottom: 4%;" />
            <span style="position: absolute;box-sizing: border-box;left: 0;right: 0;color: black;">
              <div style="height: 30px;"></div>
              <span style="color: black;font-size: 16px;vertical-align: middle;">{{ $t('test.i_rewardFor') }}</span>
              <br />
              <span style="color: black;font-size: 16px;vertical-align: middle;">{{ $t('test.i_everyFriends') }}/{{inviteReward}}<img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;margin-left: 4px;" /></span>
            </span>
          </div>
          <div style="overflow: hidden;margin-left: 4%;margin-right: 4%;">
            <div style="float: left;background-color: rgba(211, 246, 244, 1);padding: 3% 6%;border-radius: 12px;width: 36%;">
              <div>{{ $t('test.i_yourFriends') }}</div>
              <div style="color: rgba(136, 0, 252, 1);margin-top: 10%;">{{inviteNum.inviteNum}}</div>
            </div>
            <div style="float: right;background-color: rgba(211, 246, 244, 1);padding: 3% 6%;border-radius: 12px;width: 36%;">
              <div><span style="vertical-align: middle;">{{ $t('test.i_coinsReward') }}</span><img src="@/assets/common/goldIcon.png" style="width: 18px;vertical-align: middle;margin-left: 3px;" /></div>
              <div style="color: rgba(136, 0, 252, 1);margin-top: 10%;">{{inviteNum.totalGoldNum}}</div>
            </div>
          </div>

          <div class="invite-code" style="margin-top: 10%;color: black;">
            {{$t('inviteFriend.i_code')}}：
            <span class="invite-code-text">
              <span @click="copyVal($event.target.innerText)" style="color: black;">{{inviteCode}}</span>
              <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
                <img src="@/assets/common/copyIcon.png" style="width: 10px;margin-bottom: 8px;" />
              </span>
            </span>
          </div>

          <div style="overflow: hidden;margin-top: 10%;">
            <div @click="tipsDialog = true" style="position: relative;float: left;margin-left: 15%;">
              <img src="@/assets/images/invite/inviteBtn.png" style="width: 220px;margin-bottom: 4%;" />
              <span style="position: absolute;box-sizing: border-box;left: 0;right: 0;color: black;">
                <div style="height: 8px;"></div>
                <span style="color: black;font-size: 16px;vertical-align: middle;color: white;">{{ $t('test.i_inviteNow') }}</span>
              </span>
            </div>
            <span @click="$router.push('/inviteLog')" style="float: left;">
              <svg style="vertical-align: middle;margin-left: 20px;margin-top: 4px;" t="1655965911443" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2350" width="32" height="32">
                <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2351" fill="#000000"></path>
                <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2352" fill="#000000"></path>
              </svg>
            </span>
          </div>
          <div style="margin-left: 4%;margin-top: 10%;">
            <el-form style="text-align: left;">
              <el-form-item :label="$t('inviteFriend.i_friendCode')" prop="inviteCode">
                <div style="">
                  <span style="font-size: 20px;color:rgba(0, 183, 0, 1);vertical-align: middle;">+{{beInviteReward}}</span>
                  <img style="width: 15px;margin-left: 1px;vertical-align: middle;margin-right: 2px;" src="@/assets/common/goldIcon.png" />
                  <span style="color: rgba(181, 180, 187, 1);vertical-align: middle;">({{ $t('test.i_onlyOnce') }})</span>
                </div>
                <div>
                  <el-input type="text" v-model="inviteForm.inviteCode" :placeholder="$t('inviteFriend.i_enterCode')" style="width: 60%;" />
                  <el-button v-if="isInvite == false" @click="goInvite()" round style="background-color: rgba(141, 105, 253, 1);color: white;margin-left: 4%;">
                    {{ $t('test.i_confirmFriends') }}
                  </el-button>
                  <el-button v-else round style="background-color: rgba(184, 175, 212, 1);color: white;margin-left: 4%;">
                    {{ $t('test.i_confirmFriends') }}
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
            <div style="height: 30px;"></div>
          </div>
        </div>
      </div>
      <div style="color: rgba(141, 134, 151, 1);">
        <div style="margin-top: 5%;">{{ $t('test.i_inviteRules') }}：</div>
        <div style="display: inline-flex;margin-top: 6%;font-size: 14px;">
          <div style="width: 90px;">
            <img src="@/assets/images/invite/step1.png" style="width: 40px;" />
            <div>1.{{ $t('test.i_yourFriendsDownloadApp') }}</div>
          </div>
          <div style="width: 90px;margin: auto 20px auto 20px;">
            <img src="@/assets/images/invite/step2.png" style="width: 40px;" />
            <div>2.{{ $t('test.i_acitveGiftCard') }}</div>
          </div>
          <div style="width: 90px;">
            <img src="@/assets/images/invite/step3.png" style="width: 40px;" />
            <div>3.{{ $t('test.i_enterInvitationCode') }}</div>
          </div>
        </div>
        <div style="height: 20px;"></div>
      </div>

      <el-dialog :title="$t('convert.i_tips')" :visible.sync="tipsDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
        <div style="margin-bottom: 6%;text-align: center;">
          <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{$t('inviteFriend.i_tipsDialogMsg')}}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="tipsDialog = false;drawer = true;" style="width: 60%;">
            {{$t('convert.i_ok')}}
          </el-button>
        </div>
      </el-dialog>

      <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="drawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <img src="@/assets/common/appIcon.png" style="float: left;height: 50px;border-radius: 10px;" />
            <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;">Lucky Dynamic Island</span>
          </div>
          <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding-top: 3%;padding-bottom: 3%;border-radius: 6px;">
            <span style="">
              <a :href="inviteLink">{{ inviteLink }}</a>
            </span>
          </div>
          <div>
            <el-button round type="primary" @click="copyVal($event.currentTarget.parentElement.previousElementSibling.firstElementChild.innerText)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
              &nbsp;
              <span style="font-size: 16px;">Copy</span>
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        goldNum: 0,
        inviteForm: {
          inviteCode: ""
        },
        isInvite: false,
        tipsDialog: false,
        drawer: false,
        inviteNum: {
          inviteNum: 0,
          totalGoldNum: 0
        },
        inviteReward: 0,
        beInviteReward: 0,
        inviteLink: "",
        inviteCode: ""
      };
    },
    mounted() {
      document
        .querySelector("body")
        .setAttribute("style", "background-color: rgba(211, 246, 244, 1);");
    },
    beforeDestroy() {
      document
        .querySelector("body")
        .removeAttribute("style", "background-color: rgba(211, 246, 244, 1);");
    },
    methods: {
      goApp() {
        location.href = "luckydynamicisland://";
      },
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      getInviteFriend() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/inviteFriend",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            console.log(result.data.data);
            this.goldNum = result.data.data.goldNum;
            this.inviteReward = result.data.data.inviteReward;
            this.beInviteReward = result.data.data.beInviteReward;
            var uidInviteCode = result.data.data.inviterUidInviteCode;
            if (uidInviteCode != null) {
              this.isInvite = true;
            }
            this.inviteForm.inviteCode = uidInviteCode;
            this.inviteNum = result.data.data.inviteNum;
            this.inviteLink = result.data.data.inviteLink;
            this.inviteCode = result.data.data.inviteCode;
          } else if (result.data.code == 0) {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goInvite() {
        if (
          this.inviteForm.inviteCode != null &&
          this.inviteForm.inviteCode != ""
        ) {
          this.isLoading = true;
          this.$axios({
            url: "/island/giftWeb/web/checkInvite",
            method: "get",
            params: {
              inviteCode: this.inviteForm.inviteCode
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "success",
                center: true
              });
              this.getInviteFriend();
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        }
      }
    },
    created() {
      this.getInviteFriend();
    }
  };
</script>

<style>
  #invite-friend-layout {
    width: 100%;
    height: 100%;
    background-color: rgba(211, 246, 244, 1) !important;
  }
  #invite-friend-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
  #invite-friend-layout .el-input__inner {
    background-color: rgba(211, 246, 244, 1);
  }
  #invite-friend-layout .el-drawer {
    border-radius: 20px 20px 0 0 !important;
  }
</style>
