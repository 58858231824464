<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 版心 -->
    <div id="get-gift-code-layout">
      <div class="i-page-header">
        <img src="@/assets/common/goBack.png" style="width: 24px;float: left;vertical-align: middle;" @click="goApp()" />
        <div style="text-align: center;vertical-align: middle;font-size: 20px;margin-top: -2px;margin-right: 20px;">{{ $t('giftCode.i_giftCode') }}</div>
      </div>
      <div style="margin-top: 6%;">
        <span style="position: absolute;left: 4%;color: rgba(255, 234, 94, 1);font-weight: 900;-webkit-text-stroke: 1px black;font-size: 20px;">{{ bno }}</span>
        <img src="@/assets/images/giftCodeGoldCodeIcon.png" style="width: 100px;vertical-align: middle;" />
        <div v-if="giftCode==null">
          <div v-if="giftCodePercentage>0">
            <div v-if="!isOk">
              <div style="margin-top: 4%;color: white;">{{ $t('giftCode.i_countdown') }}：</div>
              <div style="color: rgba(76, 105, 153, 1);">({{ $t('giftCode.i_beCountdownEnd') }})</div>
              <div style="margin-top: 6%;color: rgba(99, 171, 177, 1);font-weight: bold;font-size: 22px;">
                <span style="margin: 2%;background-color: white;border-radius: 6px;padding: 2%;">{{ countDownHours }}</span>
                <span style="font-size: 20px;color: white;">:</span>
                <span style="margin: 2%;background-color: white;border-radius: 6px;padding: 2%;">{{ countDownMinutes }}</span>
                <span style="font-size: 20px;color: white;">:</span>
                <span style="margin: 2%;background-color: white;border-radius: 6px;padding: 2%;">{{ countDownSeconds }}</span>
              </div>
            </div>
            <div v-else>
              <div style="color: white;font-size: 18px;margin: 11% auto 16% auto;">{{ $t('giftCode.i_giftCodesBeingIssued') }}...</div>
            </div>
          </div>
          <div v-else>
            <div style="color: white;font-size: 18px;margin-top: 7%;">{{ $t('giftCode.i_giftCodeSoldOut') }}</div>
            <div style="color: rgba(76, 105, 153, 1);margin-bottom: 14%;">({{ $t('giftCode.i_pleaseWaitNextGiftCodes') }})</div>
          </div>
          <div style="margin: 5% 6%;">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="num/codeNum*100" :format="format"></el-progress>
          </div>
          <el-button type="primary" v-if="isOk&&giftCodePercentage>0" round @click="getGiftCode()" style="width: 60%;margin: 5%;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">{{ $t('giftCode.i_getItNow') }}</el-button>
          <el-button type="info" v-else-if="giftCodePercentage==0" round style="width: 60%;margin: 5%;">{{ $t('giftCode.i_soldOut') }}</el-button>
          <el-button type="info" v-else round style="width: 60%;margin: 5%;">{{ $t('giftCode.i_aboutToStart') }}</el-button>
        </div>
        <div v-else style="margin: auto 8%;">
          <div style="text-align: left;margin: 6% auto 5% auto;color: white;">{{ $t('giftCode.i_yourGiftCode') }}：</div>
          <div style="background-color: white;border-radius: 6px;margin-bottom: 4%;">
            <div style="padding: 5% 0;font-size: 36px;color: rgba(120, 222, 190, 1);">{{ giftCode }}</div>
            <div style="color: rgba(167, 164, 191, 1);margin-top: -12px;font-size: 14px;height: 24px;">(Lucky Dynamic Island)</div>
          </div>
          <el-button type="primary" @click="copyVal(giftCode)" round style="width: 60%;margin: 9% 5% 5% 5%;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">
            <img src="@/assets/common/copyIconW.png" style="width: 12px;vertical-align: middle;" />
            <span style="vertical-align: middle;margin-left: 6%;">{{ $t('common.i_copyC') }}</span>
          </el-button>
        </div>
      </div>
      <div style="margin-top: 24%;">
        <div style="overflow: hidden;margin-top: 10%;">
          <div style="border: 1px solid rgba(76, 105, 153, 1);width: 28%;float: left;margin-left: 10%;margin-top: 12px;"></div>
          <img src="@/assets/images/changeIcon.png" style="width: 32px;vertical-align: middle;" />
          <div style="border: 1px solid rgba(76, 105, 153, 1);width: 28%;float: right;margin-right: 10%;margin-top: 12px;"></div>
          <div style="font-size: 14px;color: rgba(76, 105, 153, 1);margin-top: 6px;">{{ $t('giftCode.i_redeemGiftCode') }}</div>
          <div style="color: rgba(76, 105, 153, 1);margin-top: 2px;">Lucky Dynamic Island</div>
        </div>
        <el-button type="primary" @click="show=true" round style="width: 60%;margin: 5%;background-color: rgba(76, 105, 153, 1);padding: 2%;">
          <img src="@/assets/icon/luckyDynamicIslandIcon.png" style="width: 26px;vertical-align: middle;margin-right: 5%;" />
          <span style="vertical-align: middle;font-size: 16px;">{{ $t('giftCode.i_goExchange') }}</span>
        </el-button>
      </div>

      <el-dialog :title="$t('giftCode.i_congratulations')" :visible.sync="giftCodeDialog" width="70%" center>
        <div style="color: rgba(136, 0, 252, 1);text-align: center;font-size: 32px;margin-top: 15%;">{{ giftCode }}</div>
        <div style="color: rgba(167, 164, 191, 1);font-size: 14px;text-align: center;margin-bottom: 25%;">(Lucky Dynamic Island)</div>
        <div style="text-align: center;">
          <el-button type="primary" @click="copyVal(giftCode);show=true" round style="width: 80%;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">{{ $t('giftCode.i_copyAndGoTutorial') }}</el-button>
        </div>
      </el-dialog>

      <div class="popup" v-show="show">
        <div style="color: white;margin-top: 10%;margin-left: 5%;">
          <div>
            <div style="text-align: left;">Step1：{{ $t('giftCode.i_step1') }}</div>
            <img src="@/assets/images/step1Img.png" style="width: 66%;margin-top: 2%;" />
          </div>
          <div>
            <div style="text-align: left;">Step2：{{ $t('giftCode.i_step3') }}</div>
            <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/step3Img.png" style="width: 66%;margin-top: 2%;" />
            <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/step3-1Img.png" style="width: 66%;margin-top: 2%;" />
          </div>
          <div style="margin: 8% auto 2% auto;">
            <div style="text-align: left;">Step3：{{ $t('giftCode.i_step4') }}</div>
            <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/step4Img.png" style="width: 66%;margin-top: 2%;" />
            <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/step4-1Img.png" style="width: 66%;margin-top: 2%;" />
          </div>
          <el-button type="primary" @click="goApp()" round style="width: 70%;background: linear-gradient(90deg, rgb(198, 4, 247) 20%, rgb(247, 96, 96) 100%);">{{ $t('giftCode.i_openApp') }}</el-button>
          <div>
            <img src="@/assets/images/x.png" @click="show = false" style="width: 14%;margin-top: 6%;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        campaign: null,
        giftCodeTime: null,
        timeInterval: null,
        countDown: null,
        countDownHours: "00",
        countDownMinutes: "00",
        countDownSeconds: "00",
        isOk: false,
        codeNum: 1,
        num: 0,
        giftCodePercentage: null,
        giftCodeDialog: false,
        giftCode: null,
        bno: null,
        show: false
      };
    },
    mounted() {
      document
        .querySelector("body")
        .setAttribute("style", "background-color: rgba(132, 193, 229, 1);");
    },
    beforeDestroy() {
      document
        .querySelector("body")
        .removeAttribute("style", "background-color: rgba(132, 193, 229, 1);");
    },
    methods: {
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      format(percentage) {
        percentage = percentage.toFixed(1);
        this.giftCodePercentage = percentage;
        return percentage == 0
          ? this.$t("giftCode.i_soldOut")
          : this.$t("giftCode.i_remaining") + `${percentage}%`;
      },
      toGiftCode() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/giftCode",
          method: "get",
          params: {
            campaign: this.campaign
          }
        }).then((result) => {
          if (result.data.code == 1) {
            //console.log(result.data.data);
            this.giftCodeTime = result.data.data.giftCodeTime;
            this.codeNum = result.data.data.codeNum;
            this.num = result.data.data.num;
            this.bno = result.data.data.bno;
            localStorage.setItem("appScheme", "luckydynamicisland://");
            this.timeInterval = setInterval(() => {
              // 方法区
              this.countDown =
                Date.now() + (this.giftCodeTime - Date.now()) + 1000;

              var dateTime = new Date(this.countDown - 500 - Date.now());
              this.countDownHours = parseInt(dateTime / 1000 / 60 / 60);
              if (this.countDownHours < 10) {
                this.countDownHours = "0" + this.countDownHours;
              }
              this.countDownMinutes = dateTime.getMinutes();
              if (this.countDownMinutes < 10) {
                this.countDownMinutes = "0" + this.countDownMinutes;
              }
              this.countDownSeconds = dateTime.getSeconds();
              if (this.countDownSeconds < 10) {
                this.countDownSeconds = "0" + this.countDownSeconds;
              }
              //console.log(Date.now() - this.countDown);
              if (Date.now() - this.countDown >= -1000) {
                this.isOk = true;
                this.countDownHours = "00";
                this.countDownMinutes = "00";
                this.countDownSeconds = "00";
                clearInterval(this.timeInterval);
                this.timeInterval = null;
                return;
              }
            }, 1000);
            // setTimeout(() => {
            this.isLoading = false;
            // }, 800);
          }
        });
      },
      getGiftCode() {
        this.isLoading = true;
        this.$axios({
          url: "/island/giftWeb/web/getGiftCode",
          method: "get",
          params: {
            campaign: this.campaign
          }
        }).then((result) => {
          if (result.data.code == 1) {
            //console.log(result.data.data);
            this.isLoading = false;
            this.giftCode = result.data.data.giftCode;
            if (this.giftCode != null) {
              localStorage.setItem(this.campaign, result.data.data.giftCode);
              this.giftCode = localStorage.getItem(this.campaign);
              this.giftCodeDialog = true;
            }
            this.codeNum = result.data.data.codeNum;
            this.num = result.data.data.num;
            localStorage.setItem("appScheme", "luckydynamicisland://");
          }
        });
      },
      goApp() {
        location.href = localStorage.getItem("appScheme");
      }
    },
    created() {
      if (this.$route.query.campaign != null) {
        this.campaign = this.$route.query.campaign;
      }
      this.toGiftCode();
      this.giftCode = localStorage.getItem(this.campaign);
    }
  };
</script>

<style>
  #get-gift-code-layout {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(115, 234, 174, 1) 0%,
      rgba(132, 193, 229, 1) 100%
    );
  }
  #get-gift-code-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
  #get-gift-code-layout .el-statistic .con .number {
    color: rgba(99, 171, 177, 1) !important;
    font-size: 24px !important;
  }
  #get-gift-code-layout .el-progress-bar__outer {
    border-radius: 0 !important;
    background-color: rgba(255, 255, 255, 1) !important;
  }
  #get-gift-code-layout .el-progress-bar__inner {
    border-radius: 0 !important;
    background-color: rgba(76, 105, 153, 1) !important;
  }
  #get-gift-code-layout .el-progress-bar__innerText {
    color: rgba(124, 212, 203, 1) !important;
  }

  #get-gift-code-layout .hd1 {
    transform: rotate(-5deg);
    width: 50%;
    height: 50px;
  }
  #get-gift-code-layout .hd2 {
    transform: rotate(3deg);
    width: 50%;
    height: 50px;
  }
  #get-gift-code-layout .popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
  }
</style>
